import React, { Component } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"

import "./app.scss"

import Home from "./pages/Home"

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Route exact path='/' component={Home} />
        </>
      </Router>
    )
  }
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
