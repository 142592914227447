import React, { Component } from 'react'

import './style.scss'

class Navbar extends Component {
  render() {
    return (
      <div className='navbar'>
        <div className='container'>
          <div className='brand-logo'>
            <a href='/'>
              <img
                src={process.env.PUBLIC_URL + '/images/icon.png'}
                alt='kaheretech'
              />
            </a>
          </div>
          <ul className='links'>
            <li>
              <a
                href='mailto:hello@kaheretech.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                contact us
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Navbar
