import React, { Component } from 'react'

import { WebIcon, MobileIcon, OutsourcingIcon, CTOIcon } from '../Icons'

import './style.scss'

class ServiceItem extends Component {
  render() {
    let icon = null

    if (this.props.type === 'web') {
      icon = <WebIcon className='_icon' />
    } else if (this.props.type === 'mobile') {
      icon = <MobileIcon />
    } else if (this.props.type === 'cto') {
      icon = <CTOIcon />
    } else {
      icon = <OutsourcingIcon />
    }
    return (
      <div className='service-item'>
        <div className='icon'>{icon}</div>
        <span className='title'>{this.props.title}</span>
        <p>{this.props.description}</p>
      </div>
    )
  }
}

export default ServiceItem
