import React, { Component } from 'react'
import Button from '../Button'

import './style.scss'

class Hero extends Component {
  render() {
    return (
      <div className='hero'>
        <div className='container'>
          <div className='content'>
            <h1>Your technical partner for software development.</h1>
            <p className='gray-text'>
              We are warriors-on-demand in <mark>Innovation</mark>. We create
              tools that help you become more <mark>performant</mark> and{' '}
              <mark>focus</mark> on your core business.
            </p>
            <Button title='contact us' href='mailto:hello@kaheretech.com' />
          </div>
          <div className='hero-img'>
            <img
              src={process.env.PUBLIC_URL + '/images/team_work.svg'}
              alt=''
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
