import React, { Component } from 'react'

import './style.scss'

class Button extends Component {
  render() {
    return this.props.href ? (
      <>
        <a
          className='btn'
          href={this.props.href}
          target='_blank'
          rel='noopener noreferrer'
        >
          {this.props.title}
        </a>
      </>
    ) : (
      <>
        <button className='btn'>{this.props.title}</button>
      </>
    )
  }
}

export default Button
