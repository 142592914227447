import React from 'react'

const WebIcon = (props) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 480 480'
      width='60'
      height='60'
    >
      <g>
        <g>
          <rect x='232' y='400' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M472,416h-8V128c0-17.673-14.327-32-32-32h-48V32c0-17.673-14.327-32-32-32H128c-17.673,0-32,14.327-32,32v64H48
			c-17.673,0-32,14.327-32,32v288H8c-4.418,0-8,3.582-8,8v24c0,17.673,14.327,32,32,32h416c17.673,0,32-14.327,32-32v-24
			C480,419.582,476.418,416,472,416z M112,32c0-8.837,7.163-16,16-16h224c8.837,0,16,7.163,16,16v32H112V32z M112,80h256v232
			c0,8.837-7.163,16-16,16H128c-8.837,0-16-7.163-16-16V80z M128,344h224c17.673,0,32-14.327,32-32V144h32v224H64V144h32v168
			C96,329.673,110.327,344,128,344z M32,128c0-8.837,7.163-16,16-16h48v16H56c-4.418,0-8,3.582-8,8v240c0,4.418,3.582,8,8,8h368
			c4.418,0,8-3.582,8-8V136c0-4.418-3.582-8-8-8h-40v-16h48c8.837,0,16,7.163,16,16v288H312c-2.12-0.002-4.155,0.839-5.656,2.336
			L292.688,432H187.312l-13.656-13.664c-1.501-1.497-3.536-2.338-5.656-2.336H32V128z M464,448c0,8.837-7.163,16-16,16H32
			c-8.837,0-16-7.163-16-16v-16h148.688l13.656,13.656c1.5,1.5,3.534,2.344,5.656,2.344h112c2.122,0,4.156-0.844,5.656-2.344
			L315.312,432H464V448z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='136' y='256' width='208' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='136' y='288' width='192' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='184' y='224' width='160' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='128' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='160' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='192' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='152' y='224' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M212.8,134.4l-9.6-12.8l-32,24c-3.535,2.651-4.251,7.665-1.6,11.2c0.455,0.606,0.994,1.145,1.6,1.6l32,24l9.6-12.8
			L189.336,152L212.8,134.4z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M310.4,147.2c-0.455-0.606-0.994-1.145-1.6-1.6l-32-24l-9.6,12.8l23.464,17.6L267.2,169.6l9.6,12.8l32-24
			C312.335,155.749,313.051,150.735,310.4,147.2z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x='196.925'
            y='144'
            transform='matrix(0.3715 -0.9284 0.9284 0.3715 9.7248 318.3653)'
            width='86.16'
            height='16'
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

const MobileIcon = (props) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 480 480'
      width='60'
      height='60'
    >
      <g>
        <g>
          <path
            d='M456,208c4.418,0,8-3.582,8-8V40c0-4.418-3.582-8-8-8H288V8c0-4.418-3.582-8-8-8H24c-4.418,0-8,3.582-8,8v464
			c0,4.418,3.582,8,8,8h256c4.418,0,8-3.582,8-8v-24h168c4.418,0,8-3.582,8-8V280c0-4.418-3.582-8-8-8H288v-64H456z M32,16h240v16
			h-24c-4.418,0-8,3.582-8,8v24H32V16z M32,80h208v120c0,4.418,3.582,8,8,8h24v64h-24c-4.418,0-8,3.582-8,8v120H32V80z M272,464H32
			v-48h208v24c0,4.418,3.582,8,8,8h24V464z M448,288v144H256V288H448z M256,192V48h192v144H256z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='160' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='128' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M211.872,127.992C198.515,107.993,176.05,95.989,152,96c-39.742-0.018-71.974,32.185-71.992,71.928
			C79.997,191.977,92.001,214.443,112,227.8V336c-0.001,1.817,0.617,3.581,1.752,5l32,40c2.759,3.451,7.794,4.011,11.244,1.252
			c0.462-0.37,0.882-0.789,1.252-1.252l32-40c1.135-1.419,1.753-3.183,1.752-5V227.8
			C225.049,205.726,233.946,161.041,211.872,127.992z M152,363.2L136.64,344h30.72L152,363.2z M128,328v-40h16v40H128z M176,328h-16
			v-40h16V328z M176,272h-48v-16h48V272z M200.497,195.951c-4.915,8.513-11.984,15.582-20.497,20.497v0.008
			c-2.473,1.428-3.997,4.065-4,6.92V240h-16v-44.688l21.656-21.656l-11.312-11.312L152,180.688l-18.344-18.344l-11.312,11.312
			L144,195.312V240h-16v-16.632c-0.003-2.855-1.527-5.492-4-6.92c-26.784-15.464-35.961-49.713-20.497-76.497
			c15.464-26.784,49.713-35.961,76.497-20.497C206.784,134.917,215.961,169.166,200.497,195.951z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M301.336,104L324.8,86.4l-9.6-12.8l-32,24c-3.535,2.651-4.251,7.665-1.6,11.2c0.455,0.606,0.994,1.145,1.6,1.6l32,24
			l9.6-12.8L301.336,104z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M422.4,99.2c-0.455-0.606-0.994-1.145-1.6-1.6l-32-24l-9.6,12.8l23.464,17.6L379.2,121.6l9.6,12.8l32-24
			C424.335,107.749,425.051,102.735,422.4,99.2z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x='308.926'
            y='96.032'
            transform='matrix(0.3715 -0.9284 0.9284 0.3715 124.6551 392.2022)'
            width='86.16'
            height='16'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='144' y='432' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='280' y='160' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='312' y='160' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='344' y='160' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='376' y='160' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='408' y='160' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='336' y='304' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='368' y='304' width='64' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='336' y='336' width='96' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='272' y='368' width='160' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='272' y='400' width='128' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M312,304h-32c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-32C320,307.582,316.418,304,312,304z
			 M304,336h-16v-16h16V336z'
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

const OutsourcingIcon = (props) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 480.001 480.001'
      width='60'
      height='60'
    >
      <g>
        <g>
          <path
            d='M120,216.001c-17.673,0-32,14.327-32,32s14.327,32,32,32s32-14.327,32-32S137.674,216.001,120,216.001z M120,264.001
			c-8.837,0-16-7.163-16-16c0-8.837,7.163-16,16-16s16,7.163,16,16C136.001,256.837,128.837,264.001,120,264.001z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M314.401,208.001H448c17.673,0,32-14.327,32-32v-112c0-17.673-14.327-32-32-32H296v-24c0-4.418-3.582-8-8-8H8
			C3.631-0.048,0.049,3.454,0,7.824C0,7.883,0,7.941,0,8.001v408c0.003,3.442,2.206,6.496,5.472,7.584l168,56
			c0.814,0.276,1.668,0.416,2.528,0.416c4.418,0,8-3.582,8-8v-48h28.448c5.672,9.867,16.171,15.964,27.552,16h208
			c17.673,0,32-14.327,32-32v-80c0-17.673-14.327-32-32-32h-133.6L296,283.72v-63.44L314.401,208.001z M448,48.001
			c8.837,0,16,7.163,16,16v32h-240v-32c0-8.837,7.163-16,16-16H448z M168.001,460.897L16,410.233V19.105l152,50.664V460.897z
			 M208.001,328.001v80h-24v-344c-0.003-3.442-2.206-6.496-5.472-7.584L57.289,16.001H280v16h-40c-17.673,0-32,14.327-32,32v112
			c0,17.673,14.327,32,32,32h16v24c-0.001,4.418,3.581,8.001,7.999,8.001c1.581,0,3.126-0.468,4.441-1.345l11.56-7.712v42.112
			l-11.56-7.712c-3.675-2.452-8.643-1.461-11.095,2.215c-0.877,1.315-1.345,2.86-1.345,4.441v24h-16
			C222.327,296.001,208.001,310.327,208.001,328.001z M307.56,310.657c1.316,0.874,2.86,1.341,4.44,1.344h136
			c8.837,0,16,7.163,16,16v80c0,8.837-7.163,16-16,16h-208c-8.837,0-16-7.163-16-16v-80c0-8.837,7.163-16,16-16h24
			c4.418,0,8-3.582,8-8v-17.056L307.56,310.657z M272,217.056v-17.056c0-4.418-3.582-8-8-8h-24c-8.837,0-16-7.163-16-16v-64h240v64
			c0,8.837-7.163,16-16,16H312c-1.58,0.003-3.124,0.47-4.44,1.344L272,217.056z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='248.001' y='160.001' width='192' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='264' y='128.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='296' y='128.001' width='144' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='240.001' y='64.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='272' y='64.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='304' y='64.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M277.656,341.657l-11.312-11.312l-16,16c-3.123,3.124-3.123,8.188,0,11.312l16,16l11.312-11.312l-10.344-10.344
			L277.656,341.657z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M341.656,346.345l-16-16l-11.312,11.312l10.344,10.344l-10.344,10.344l11.312,11.312l16-16
			C344.78,354.533,344.78,349.468,341.656,346.345z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x='270.7'
            y='343.972'
            transform='matrix(0.3164 -0.9486 0.9486 0.3164 -131.5407 521.41)'
            width='50.6'
            height='16'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='360' y='344.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='248.001' y='392.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='280' y='392.001' width='160' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='392' y='344.001' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='424' y='344.001' width='16' height='16' />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

const CTOIcon = (props) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 480 480'
      width='60'
      height='60'
    >
      <g>
        <g>
          <rect x='96' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='128' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='160' y='32' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M432,0H96C78.327,0,64,14.327,64,32v144H24c-4.418,0-8,3.582-8,8v144c0,4.418,3.582,8,8,8h40v24H24c-4.418,0-8,3.582-8,8
			v64c0,4.418,3.582,8,8,8h40v8c0,17.673,14.327,32,32,32h336c17.673,0,32-14.327,32-32V32C464,14.327,449.673,0,432,0z M64,424H32
			v-48h32V424z M32,320V192h208v128H32z M80,376h16v48H80V376z M448,448c0,8.837-7.163,16-16,16H96c-8.837,0-16-7.163-16-16v-8h272
			c1.238,0.014,2.462-0.26,3.576-0.8l64-32c3.954-1.971,5.562-6.774,3.591-10.729c-0.775-1.556-2.036-2.816-3.591-3.591l-64-32
			c-1.035-0.505-2.168-0.778-3.32-0.8c-0.088,0-0.168-0.064-0.256-0.064H80V336h168c4.418,0,8-3.582,8-8V184c0-4.418-3.582-8-8-8H80
			V80h368V448z M344,408v16H112v-16H344z M112,392v-16h232v16H112z M360,419.056v-38.112L398.112,400L360,419.056z M448,64H80V32
			c0-8.837,7.163-16,16-16h336c8.837,0,16,7.163,16,16V64z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M108.8,238.4l-9.6-12.8l-32,24c-3.535,2.651-4.251,7.665-1.6,11.2c0.455,0.606,0.994,1.145,1.6,1.6l32,24l9.6-12.8
			L85.336,256L108.8,238.4z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M206.4,251.2c-0.455-0.606-0.994-1.145-1.6-1.6l-32-24l-9.6,12.8l23.464,17.6L163.2,273.6l9.6,12.8l32-24
			C208.335,259.749,209.051,254.735,206.4,251.2z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x='92.917'
            y='247.987'
            transform='matrix(0.3715 -0.9284 0.9284 0.3715 -152.1923 287.158)'
            width='86.16'
            height='16'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='104' y='136' width='152' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='136' y='104' width='120' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='104' y='104' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='304' y='320' width='120' height='16' />
        </g>
      </g>
      <g>
        <g>
          <rect x='272' y='320' width='16' height='16' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M392,235.8c33.063-22.091,41.958-66.803,19.867-99.867C389.775,102.87,345.063,93.976,312,116.067
			s-41.958,66.803-19.867,99.867c5.253,7.862,12.004,14.613,19.867,19.867V248h-16v16h16v24c0,4.418,3.582,8,8,8h64
			c4.418,0,8-3.582,8-8v-24h16v-16h-16V235.8z M376,280h-48v-16h48V280z M380,224.448c-2.473,1.428-3.997,4.065-4,6.92V248h-16
			v-44.688l21.656-21.656l-11.312-11.312L352,188.688l-18.344-18.344l-11.312,11.312L344,203.312V248h-16v-16.632
			c-0.003-2.855-1.527-5.492-4-6.92c-26.757-15.511-35.874-49.776-20.363-76.533C313.638,130.663,332.059,120.03,352,120
			c30.928,0.047,55.962,25.157,55.915,56.085C407.884,196.026,397.252,214.447,380,224.448z'
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

export { WebIcon, MobileIcon, OutsourcingIcon, CTOIcon }
