import React, { Component } from 'react'

import Navbar from '../../components/Navbar'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import ServiceItem from '../../components/ServiceItem'
import Footer from '../../components/Footer'

class Home extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Hero />

        {/* <div className='products'>
          <div className='container'>
            <div className='item'>
              <a href='/'>
                <img
                  src={process.env.PUBLIC_URL + '/images/kaherecode.png'}
                  alt='Kaherecode'
                />
              </a>
            </div>
            <div className='item'>
              <a href='/'>
                <img
                  src={process.env.PUBLIC_URL + '/images/kahereacademy.png'}
                  alt='Kahereacademy'
                />
              </a>
            </div>
            <div className='item'>
              <a href='/'>
                <img
                  src={process.env.PUBLIC_URL + '/images/kaheredu.png'}
                  alt='Kaheredu'
                />
              </a>
            </div>
            <div className='item'>
              <a href='/'>
                <img
                  src={process.env.PUBLIC_URL + '/images/kaheresto.png'}
                  alt='Kaheresto'
                />
              </a>
            </div>
          </div>
        </div> */}

        <div className='section services'>
          <div className='container'>
            <div className='left-side services-list'>
              <ServiceItem
                type='web'
                title='web development'
                description='We build well designed and robuste web apps using modern technologies.'
              />
              <ServiceItem
                type='mobile'
                title='mobile development'
                description='We build elegant cross platform mobile apps using modern web technologies.'
              />
              <ServiceItem
                type='cto'
                title='CTO as as service'
                description='We accompany new startup founder in building their MVP to reach their market.'
              />
              <ServiceItem
                type='outsourcing'
                title='outsourcing'
                description='We build custom software applications tailored to your business.'
              />
            </div>
            <div className='right-side content'>
              <h1 className='title'>services</h1>
              <h2 className='subtitle'>
                We provide awesome services to our customers
              </h2>
              <p className='gray-text'>
                We put Human at the center of our developments and develop
                robust and well designed applications, in an agile approach,
                with clear, robust and maintainable source code.
              </p>
              <Button title='get in touch' href='mailto:hello@kaheretech.com' />
            </div>
          </div>
        </div>

        <div className='section'>
          <div className='container'>
            <div className='left-side'>
              <h1 className='title'>about us</h1>
              <h2 className='subtitle'>
                We Plan, Design, Develop and Maintain awesome Project
              </h2>
              <p className='gray-text'>
                We are a team of young and passionate developers and
                entrepreneurs eager to build great products. We help our
                customers build successful and scalable web apps.
              </p>
              <Button
                title='tell us about your project'
                href='mailto:hello@kaheretech.com'
              />
            </div>
            <div className='right-side'>
              <img
                className='section-img'
                src={process.env.PUBLIC_URL + '/images/web_development.svg'}
                alt=''
              />
            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
}

export default Home
