import React, { Component } from 'react'

import './style.scss'

class Footer extends Component {
  render() {
    return (
      <footer>
        {/* <div className='container'>
          <div className='services'>
            <h2>services</h2>
            <ul>
              <li>
                <a href='/'>web development</a>
              </li>
              <li>
                <a href='/'>mobile development</a>
              </li>
              <li>
                <a href='/'>cto as a service</a>
              </li>
              <li>
                <a href='/'>outsourcing</a>
              </li>
            </ul>
          </div>

          <div>
            <h2>quick links</h2>
            <ul>
              <li>
                <a href='/'>home</a>
              </li>
              <li>
                <a href='/'>services</a>
              </li>
              <li>
                <a href='/'>about us</a>
              </li>
              <li>
                <a href='/'>portfolio</a>
              </li>
              <li>
                <a href='/'>blog</a>
              </li>
              <li>
                <a href='/'>contact us</a>
              </li>
            </ul>
          </div>

          <div>
            <h2>contact</h2>
            <ul>
              <li>
                <i className='fas fa-map-marked' />{' '}
                <a href='/'>Dakar, Senegal</a>
              </li>
              <li>
                <i className='fas fa-envelope' />{' '}
                <a href='mailto:contact@kaherecode.com' className='mail'>
                  contact@kaherecode.com
                </a>
              </li>
              <li>
                <i className='fas fa-phone-square' /> +221 77 285 13 02
              </li>
              <li className='socials'>
                <a href='/'>
                  <i className='fab fa-facebook-f' />
                </a>
                <a href='/'>
                  <i className='fab fa-twitter' />
                </a>
                <a href='/'>
                  <i className='fab fa-linkedin-in' />
                </a>
                <a href='/'>
                  <i className='fab fa-medium-m' />
                </a>
                <a href='/'>
                  <i className='fab fa-instagram' />
                </a>
              </li>
            </ul>
          </div>
        </div> */}
        <div className='copyright'>
          <div className='container'>
            &copy; {new Date().getFullYear()} All Rights Reserved.{' '}
            <a href='/'>kaheretech.com</a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
